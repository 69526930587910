import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { common } from './_common.js';

import { stalker } from './_stalking_line.js';


export class Framework {
	constructor(){
		this.firstVisit = true;
		this.canClick 	= true;
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
		console.log(this.pageinfo);
	}
	
	checkURL(){
		if(this.canClick){
			this.canClick = false;
			//path
			this.changeContent();
		}
	}
	
	nav(){
		
	}
	
	changeURL(){
		
	}
	
	changeContent(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		stalker.init();
		if(this.firstVisit){
			this.firstVisit = false;
			common.init();
		}
		/*
		let imgArry = [
			"/assets/images/a.jpg",
			"/assets/images/b.jpg"
		];
		preload.startLoad(imgArry).then(res => {
		});
		*/
		common.resize();
		common.nav();
		this.reset();
		this.nav();
	}
	
	reset(){
		this.canClick = true;
	}
}