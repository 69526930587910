let grid = {
	w: 10,
	space: 0
}
class Pix {
	constructor(id, thisIndex){
		this.xIndex = thisIndex[0];
		this.yIndex = thisIndex[1];
		this.life  	= 1;
		this.color 	= "33, 97, 140"
		this.pos 	= [ this.xIndex*grid.w, this.yIndex*grid.w ];
		this.id 	= id;
		stalker.pixelArry[this.id] = this;
		this.draw();
	}
	draw(){
		let s = stalker;
		
		s.ctx.beginPath();
		//s.ctx.fillStyle = `rgba(${this.color[0]}, ${this.color[1]}, ${this.color[2]}, ${this.life})`;
		
		s.ctx.rect(this.pos[0], this.pos[1], grid.w, grid.w);
		s.ctx.fillStyle = `rgba(33, 97, 140, ${this.life})`;
		s.ctx.fill();
		//s.ctx.globalAlpha = this.life;
		
		this.life *= .9;
		if(this.life < .05){
			delete stalker.pixelArry[this.id];
		}
	}
}

export let stalker = {
	cvs: null,
	ctx: null,
	sizes: [0, 0],
	frameID: 0,
	currentMousePos: [0, 0],
	beforeIndex: [0, 0],
	pixelArry: [],
	id: 0,
	init: function(){
		stalker.cvs = document.querySelector("#canvas_line");
		stalker.ctx = stalker.cvs.getContext("2d");
		stalker.resize();
		window.addEventListener("resize", stalker.resize, false);
		document.addEventListener("mousemove", stalker.getMousePos, false);
		stalker.update();
		
	},
	resize: function(){
		let winW = window.innerWidth;
		let winH = window.innerHeight;
		stalker.sizes[0] = winW;
		stalker.sizes[1] = winH;
		
		stalker.cvs.setAttribute("width", winW);
		stalker.cvs.setAttribute("height", winH);
		stalker.cvs.style.width = winW + "px";
		stalker.cvs.style.height = winH + "px";
	},
	getMousePos: function(e){
		//console.log(e);
		let mouse = [0, 0];
		if(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
		stalker.currentMousePos = mouse;
	},
	update: function(){
		let s = stalker;
		//console.log(s.currentMousePos[0], s.beforeMousePos[0])
		let currentIndex = s.getIndex(s.currentMousePos);
		if(
			currentIndex[0] != s.beforeIndex[0] || currentIndex[1] != s.beforeIndex[1]
		){
			//console.log("moved", s.currentMousePos);
			new Pix(s.id, currentIndex);
			s.id++;
		}
		s.ctx.clearRect(0, 0, s.cvs.width, s.cvs.height);
		for(let i = 0; i < s.pixelArry.length; i++){
			if(s.pixelArry[i]){
				s.pixelArry[i].draw();
			}
		}
		//console.log(s.pixelArry);
		s.beforeIndex = currentIndex;
		s.frameID = requestAnimationFrame(s.update);
	},
	getIndex: function(pos){
		let xIndex = Math.floor( pos[0]/grid.w );
		let yIndex = Math.floor( pos[1]/grid.w );
		//console.log(pos, xIndex, yIndex);
		return [xIndex, yIndex];
		
	}
}